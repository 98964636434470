<template>
  <modal
    name="unlock-dashboard-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :click-to-close="false"
    :width="300"
    height="auto"
    @before-open="beforeOpen"
  >
    <div class="modal-container">
      <div class="modal-window-content">
        <p class="text-align-center modal__text no-search-field">
          {{ $t('dashboard.label.passwordToUnlock') }}
        </p>
        <div class="flex-column modal__input-container">
          <div class="modal__input-label">{{ $t('dashboard.label.password') }}</div>
          <form autocomplete="off" @submit.prevent="unlock">
            <input
              v-model="password"
              v-validate="'required'"
              autocomplete="new-password"
              name="password"
              class="modal__input rounded-input"
              :class="{ 'error-highlight': hasPasswordFieldError }"
              :placeholder="$t('dashboard.label.enterPassword')"
              type="password"
            />
            <field-error
              v-if="hasPasswordFieldError"
              :error-message="$t('dashboard.warning.requiredField')"
            />
          </form>
        </div>
        <div class="flex--center--center">
          <button class="rounded-button-white" @click="unlock">
            {{ $t('dashboard.action.Unlock') }}
          </button>
        </div>
      </div>
    </div>

    <unlock-failed-modal />
  </modal>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import UnlockFailedModal from '@/modules/dashboard/components/patient-card/UnlockFailedModal';

import { unlockDoctorsDashboard } from '@/api';

import { types } from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';
import FieldError from '@/modules/dashboard/components/common/FieldError';

export default {
  name: 'UnlockModal',
  components: {
    FieldError,
    UnlockFailedModal
  },
  data() {
    return {
      password: ''
    };
  },
  computed: {
    ...mapGetters({
      userId: types.getters.GET_USER_ID,
      username: types.getters.DOCTOR_USERNAME,
      currentPatient: dashTypes.getters.CURRENT_PATIENT
    }),
    hasPasswordFieldError() {
      return this.errors.has('password');
    }
  },
  methods: {
    ...mapMutations({
      setIsDashboardLocked: dashTypes.mutations.SET_IS_DASHBOARD_LOCKED
    }),
    ...mapActions({
      loadPatient: dashTypes.actions.LOAD_PATIENT
    }),
    beforeOpen() {
      this.password = '';

      document.body.classList.add('v-unlocked-modal-block-blur');
    },
    hideModal() {
      this.$modal.hide('unlock-dashboard-modal');
      document.body.classList.remove('v-unlocked-modal-block-blur');
    },
    async unlock() {
      if (!(await this.$validator.validateAll())) {
        return;
      }

      const { data: isUserAuthorized } = await unlockDoctorsDashboard(this.userId, {
        username: this.username,
        password: this.password
      });

      if (!isUserAuthorized) {
        this.$modal.show('unlock-failed-modal');

        return;
      }

      if (this.currentPatient) {
        const { id } = this.currentPatient;

        if (id) {
          await this.loadPatient(id);
        }
      }

      this.setIsDashboardLocked(false);
      this.hideModal();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/inputs';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/errors';

.modal {
  &-container {
    font-size: 15px;
  }

  &-window-content {
    padding: 50px 20px 40px;
  }

  &__text {
    color: $grey-color;
  }

  &__input {
    width: 100%;

    &-container {
      margin: 30px 0 20px;

      color: $text-color;
    }

    &-label {
      margin-bottom: 10px;

      font-size: 13px;
    }
  }
}

@media (max-width: 767px) {
  .modal {
    &__input {
      font-size: 16px;
      line-height: 19px;
    }
  }
}
</style>
