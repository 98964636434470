<template>
  <modal
    name="unlock-failed-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :click-to-close="false"
    :width="300"
    height="auto"
  >
    <div class="modal-container">
      <div class="modal-window-content">
        <p class="text-align-center modal__text">
          {{ $t('dashboard.label.passwordToUnlockIsIncorrect') }}
        </p>
        <div class="flex--center--center">
          <button class="modal__button rounded-button-white" @click="onOkClick">
            {{ $t('dashboard.button.ok') }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'UnlockFailedModal',
  methods: {
    onOkClick() {
      this.$modal.hide('unlock-failed-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';

.modal {
  &-window-content {
    padding: 60px 20px 40px;
  }

  &__text {
    height: 100px;

    margin-bottom: 60px;
    padding: 30px 20px;

    border-radius: 8px;
    background-color: $beige;

    font-size: 11px;
    line-height: 150%;
    color: $text-color;
  }

  &__button {
    width: 80px;
  }
}
</style>
