<template>
  <div>
    <router-view />
    <unlock-modal />
  </div>
</template>

<script>
import '@/modules/dashboard/assets/scss/styles.scss';

import { mapGetters } from 'vuex';
import { IdlePageTimer } from 'universkin-shared/src/utils/IdlePageTimer';

import UnlockModal from '@/modules/dashboard/components/patient-card/UnlockModal';

import { logoutMixin } from '@/modules/dashboard/mixins/logoutMixin';

import { types as dashTypes } from '@/modules/dashboard/store/types';
import rootTypes from '@/store/types';

const IDLE_TIMEOUT = 1000 * 60 * 60; // one hour

export default {
  name: 'AppDashboard',
  components: { UnlockModal },
  mixins: [logoutMixin],
  data() {
    return {
      timer: null
    };
  },
  computed: {
    ...mapGetters({
      isDashboardLocked: dashTypes.getters.IS_DASHBOARD_LOCKED,
      user: rootTypes.getters.GET_USER
    })
  },
  mounted() {
    if (!this.user) {
      this.logout();
    }

    this.timer = new IdlePageTimer(this.logout, IDLE_TIMEOUT);

    if (this.isDashboardLocked) {
      this.$modal.show('unlock-dashboard-modal');
    }
  },
  beforeDestroy() {
    this.timer.stop();
  },
  methods: {
    async logout() {
      await this.logoutAndClearModule();
    }
  }
};
</script>
