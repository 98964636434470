import throttle from 'lodash.throttle';

const NOT_IDLE_EVENTS = [
  'load',
  'mousemove',
  'mousedown',
  'touchstart',
  'click',
  'keypress',
  'scroll'
];

const BROWSER_TAB_ACTIVATED_EVENTS = ['focus', 'load'];
const BROWSER_TAB_BLURED_EVENTS = ['blur', 'unload'];

// a fraction of idle timeout which represents a throttle
// interval for timer resets
const THROTTLE_FACTOR = Math.round(1 / 360);

const BLUR_TIMESTAMP_KEY = 'blurTime';

const onBrowserTabBlured = () => {
  sessionStorage.setItem(BLUR_TIMESTAMP_KEY, Date.now().toString(10));
};
const MAX_TIMEOUT = 2 ** 32 - 1; // lodash can't handle higher value

/** Invoke a callback after web page being idle for "idleTimeout" time. */
export class IdlePageTimer {
  constructor(callback, idleTimeout) {
    if (idleTimeout > MAX_TIMEOUT || typeof idleTimeout !== 'number') {
      // eslint-disable-next-line no-console
      console.warn('Timeout exceeds maximum value 32bit integer or is not a valid number');

      return;
    }

    this.callback = callback;
    this.idleTimeout = idleTimeout;

    this.__onBrowserTabActivated = this.__onBrowserTabActivated.bind(this);
    this.__executeCallback = this.__executeCallback.bind(this);

    const throttleTimeout = idleTimeout * THROTTLE_FACTOR;

    this.__resetIdleTimeoutThrottled = throttle(
      this.__resetIdleTimeout.bind(this),
      throttleTimeout
    );

    this.__setTimeout();
    this.__setEventListeners();
  }

  stop() {
    this.__removeEventListeners();
    this.__clearIdleTimeout();
  }

  __setEventListeners() {
    NOT_IDLE_EVENTS.forEach(event => {
      window.addEventListener(event, this.__resetIdleTimeoutThrottled);
    });

    BROWSER_TAB_ACTIVATED_EVENTS.forEach(event => {
      window.addEventListener(event, this.__onBrowserTabActivated);
    });

    BROWSER_TAB_BLURED_EVENTS.forEach(event => {
      window.addEventListener(event, onBrowserTabBlured);
    });
  }

  __onBrowserTabActivated() {
    const savedBlurTime = sessionStorage.getItem(BLUR_TIMESTAMP_KEY);

    if (savedBlurTime) {
      const diff = Date.now() - savedBlurTime;

      if (diff > this.idleTimeout) {
        this.__executeCallback();
      }
    }
  }

  __removeEventListeners() {
    NOT_IDLE_EVENTS.forEach(event => {
      window.removeEventListener(event, this.__resetIdleTimeoutThrottled);
    });

    BROWSER_TAB_ACTIVATED_EVENTS.forEach(event => {
      window.removeEventListener(event, this.__onBrowserTabActivated);
    });

    BROWSER_TAB_BLURED_EVENTS.forEach(event => {
      window.removeEventListener(event, onBrowserTabBlured);
    });
  }

  __resetIdleTimeout() {
    this.__clearIdleTimeout();
    this.__setTimeout();
  }

  __clearIdleTimeout() {
    clearTimeout(this.__timer);
  }

  __setTimeout() {
    this.__timer = setTimeout(this.__executeCallback, this.idleTimeout);
  }

  __executeCallback() {
    this.callback();
    this.stop();
  }
}
