<template>
  <p class="error-message flex">
    <span class="fas fa-exclamation-triangle mt4 mr7"></span>
    <span>{{ errorMessage }}</span>
  </p>
</template>


<script>
export default {
  name: 'FieldError',
  props: {
    errorMessage: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/errors.css';
</style>

